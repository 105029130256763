.container {
  margin: 0;
  padding: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.containerA {
  width: 50%;
  padding: 2rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.containerA img {
  object-fit: contain;
  width: 2% !important;
  position: relative !important;
  height: unset !important;
  border-radius: 2rem;
}

.grid {
  margin: 2rem 2rem;
  width: 95%;
  display: grid;
  place-items: center;
}

.grid h1 {
  color: var(--primaryColor);
}
.gridItems {
  width: 100%;
  margin: 0.5rem;
}

.card h3{
  text-align: center;
  color: var(--primaryColor) !important;
}
.containerA img:hover {
  cursor: pointer;
  transform: scale(1.05);
}
.ourwork {
  height: 150px;
  color: aliceblue;
  line-height: 0.5rem;
  display: block;
}
.containerA {
  display: flex;
  width: 100% !important;
  background-color: brown;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.containerB {
  display: flex;
  width: 100% !important;
  background-color: brown;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.columnA {
  width: 25%;
}
.ourwork h1 {
  font-weight: bold;
  font-size: 4rem;
}
.ourmission {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #e9e9e9;
  color: var(--primaryColor);
  line-height: 2rem;
}
.ourmissioncolumns {
  padding: 2rem 5rem;
  width: 50%;
}
.ourtemmainContainer {
  margin: 0;
  padding: 0;
  display: flex;
}
.ourteamsubcontainerA {
  width: 30%;
  padding: 2rem;
  color: var(--primaryColor);
  line-height: 2rem;
}
.ourteamsubcontainerB {
  width: 70%;
  padding: 1rem;
}
.ourteam {
  display: block;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.ourteam h3 {
  color: var(--primaryColor);
}
.titles {
  display: block;
}
.ourteam img {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 50%;
  width: 75%;
}
.ourteamcontainer {
  display: flex;
}
.ourteamcontainer h4 {
  color: var(--lightBlue);
}
.button {
  width: 25%;
  padding: 0.5rem;
  border: solid 0.1rem;
  border-radius: 0.8rem;
  color: #fff;
  background-color: var(--primaryColor);
  cursor: pointer;
}
.button a {
  color: #fff !important;
}
.button:hover {
  border: solid "#dsadad";
  color: #fff !important;
  background-color: var(--lightBlue);
}
@media (max-width: 700px) {
  .ourwork {
    padding: 0.5rem;
  }
  .ourwork h1 {
    font-weight: bold;
    font-size: 2rem;
  }
  .ourwork h3 {
    font-size: 0.8rem;
    line-height: 1rem;
  }
  .ourmission {
    display: block;
  }
  .ourmissioncolumns {
    padding: 0.5rem 0rem;
    width: 100%;
  }
  .ourtemmainContainer {
    display: block;
  }
  .ourteamsubcontainerA {
    margin-top: -5rem;
    width: 90%;
    padding: 2rem !important;
  }
  .ourteamsubcontainerB {
    width: 95%;
    padding: 0.5;
  }
  .ourteamcontainer h3 {
    font-size: 1.1rem;
  }
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}
