.container{
    padding-right: 5rem;
    width: 96%;
    justify-items: center;
    align-items: center;
    color: var(--primaryColor);
}
.container h1{
    text-align: center;
}
.container h2{
    text-align: left;
}
.container h3{
    text-align: center;
}
.container h4{
    text-align: center;
}