/* Slider.css */
.slider-container {
  margin-top: -0.25rem;
  position: relative;
  width: 100%;
  overflow-y: hidden !important;
  background-color: rgb(202, 172, 172);
}

.slide {
  position:absolute;
  display: flex;
  opacity: 0;
  transition: opacity 1s ease-in-out  !important;
}

.slide.active {
  position:relative;
  opacity: 1;
 
}

img {
  width: 100%;
  height: auto;
}

.indicator-bar {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.indicator {
  width: 20px;
  height: 20px;
  background-color: #f0e3e3;
  border-radius: 50%;
  margin: 10px 5px;
  cursor: pointer;
  z-index: 1;
}

.indicator.active {
  background-color: var(--primaryColor)
}
