.container {
  padding: 0rem 0rem;
  text-align: justify;
  color: var(--secondaryColor);
}
.container h3 {
  color: var(--primaryColor);
  text-align: center;
}
.subcontainer {
  padding: 0rem 8rem;
}
.container h4 {
  color: var(--primaryColor);
  text-align: left;
}
.container span {
  font-style: italic;
  font-size: small;
}

.container li {
  display: list-item;
  list-style: disc;
  text-decoration: solid;
  color: var(--primaryColor) !important;
  line-height: 1.5rem;
}

.maintable {
  padding: 1rem 1rem;
  width: 100%;
}

.table {
  padding-left: 10rem;
  width: 100%;
  border: 1rem;
  color: var(--secondaryColor);
}

#table p:nth-child(even) {
  background-color: #f2f2f2;
}

#table tr:hover {
  background-color: #ddd;
}
#table td {
  padding: 0.5rem;
}

#table th {
  padding: 0.4rem;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}
.highlight {
  color: var(--greyCardTop);
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  margin-bottom: 2rem;
  text-align: justify;
  animation: bounce 1s ease-in-out;
}
.highlight:hover {
  color: var(--primaryColor);
  cursor: pointer;
}

.highlight2 {
  color: var(--greyCardTop);
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.button {
  width: 15%;
  padding: 0.5rem;
  border: none;
  border-radius: 0.5rem;
  color: #f2f2f2;
  font-weight: bold;
  cursor: pointer;
  background-color: var(--lightBlue);
  float: right;
}
.button:hover {
  background-color: var(--primaryColor);
}

.container2 {
  margin: 0;
  padding: 0;
}

.content {
  margin: 2rem;
  padding: 1rem 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: var(--primaryColor);
  text-align: center;
}
.content span {
  color: var(--lightBlue);
  font-weight: bold;
}
.content h1 {
  color: var(--primaryColor);
  text-align: center;
}
.content li {
  display: list-item;
  list-style: none;
  line-height: 1.5rem;
}

.container2 a {
  color: var(--primaryColor);
}
.container2 a:hover {
  color: var(--lightBlue);
}

.container2 h1 {
  text-align: center !important;
}
.button {
  margin-left: 1rem;
  width: fit-content;
  display: "flex";
  border: none;
  border-radius: 0.5rem;
  color: #f2f2f2;
  font-weight: bold;
  cursor: pointer;
  background-color: var(--lightBlue);
  float: left;
}
.button:hover {
  background-color: var(--primaryColor);
}

.download {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 1rem 1.5rem;
  display: flex;
}

@media (max-width: 700px) {
  .container {
    padding: 0rem;
    text-align: justify;
    color: var(--secondaryColor);
  }
  .subcontainer {
    padding: 0rem 2rem;
  }
  .content {
    padding: 1rem;
  }
  .button {
    width: 50%;
  }
  .table {
    padding-left: 1rem;
  }
  .download{
    display: block;
  }
}
