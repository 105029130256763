:root {
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

  --max-width: 1100px;
  --primaryColor: #295166;
  --lightBlue: #01ADBE;
  --secondaryColor: #373435;
  --blendRedColor: #eb1c24;
  --blendWhiteColor: #fff;
  --NavContact: #060f11;
  --greyCardTop: #606062;
  --greyCardBottom: #d2d3d5;
}
body {
  margin: 0;
  overflow-x: hidden;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a {
  color: #d7e3f5;
  text-decoration: none;
}

