.grid {
  display: grid;
  margin: 0;
  padding: 0;
  grid-template-columns: repeat(3, minmax(33%, auto));
  width: 100%;
  background-color: #f0f3f5;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
}

.card {
  padding: 1.5rem;
  text-align: left;
  border-radius: var(--border-radius);
  background: rgba(var(--card-rgb), 0);
  border: 1px solid rgba(var(--card-border-rgb), 0);
  transition: background 200ms, border 200ms;
}

.card span {
  display: inline-block;
  transition: transform 200ms;
}

.card h1 {
  text-align: center;
  font-size: 5rem;
  margin: 0.5rem;
  color: #3aafa9;
}
.card h3 {
  text-align: center;
  color: var(--lightBlue);
}

.card p {
  text-align: justify;
  opacity: 0.6;
  font-size: 1rem;
  text-transform: none;
}
.card ul {
  margin-left: 20px;
  text-decoration: none;
}
.card ul li {
  text-transform: capitalize;
  opacity: 0.6;
  font-size: 1rem;
  line-height: 2.5rem;
  list-style-type: none;
}

.card ul li a {
  color: #17242a;
  font-size: 1rem;
}

.card ul li a:hover {
  color: #3aafa9;
  font-weight: bold;
  font-size: 1rem;
  transition: 1ms;
}

.copyright {
  padding: 0.8rem;
  height: 40px;
  display: flex;
  background-color: var(--primaryColor);
  font-size: small;
  color: #fff;
}
.containerCopyright {
  width: 50%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.containerCopyright li {
  list-style: none;
  float: left;
  padding: 0rem 1rem;
  line-height: 1.5rem;
}

.copyright a {
  color: inherit !important;
  text-decoration: none !important;
}

.copyright a:hover {
  font-weight: bold;
  color: #f0f3f5 !important;
}
@media (max-width: 700px) {
  .grid {
    display: block;
  }
  .card h1 {
    text-align: center;
    font-size: 5rem;
    margin: 0.5rem;
    color: #3aafa9;
  }

  .card ul {
    margin-left: 80px;
    text-decoration: none;
  }
  .copyright {
    padding: 0.2rem;
    display: block;
    height: 100px;
  }
  .containerCopyright {
    width: 100%;
  }
}
