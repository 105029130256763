.Navbar {
  background-color: #f0f3f5;
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
 padding-top: 2rem !important;
}
.NavlinkContainer {
  width: 100%;
  display: flex;
}
.NavlinkPaddedContainer {
  width: 35%;
  background-color: #f0f3f5 !important;
}
.NavbarMenuLinks {
  background-color: #295166 !important;
  width: 65%;
  height: 40px;
  display: flex;
  justify-items: center;
  align-items: center;
  padding-left: 1rem;
}

.NavbarMenuLinks li {
  font-size: 0.8rem;
  color: #fff;
  display: inline-block;
  padding-left: 20px;
}

.leftSide {
  display: none;
}

.rightSide {
  width: 100%;
  display: flex;
}
.rightSideA {
  width: 60%;
  display: flex;
}

.rightSideAAA {
  padding-top: 4rem;
  padding-left: 5rem;
  padding-right: 2rem;
  width: 20%;
  z-index: 3;
}

.rightSideAAA img:hover {
  cursor: pointer;
  animation: swing ease-in-out 2s infinite;
}

@keyframes swing {
  0% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}
.rightSideAAB {
  padding-top: 4rem;
  width: 80%;
  align-items: center;
  justify-content: center;
}

.rightSideABA {
  padding-top: 0.5rem;
}
.rightSideABB {
  padding-top: 1.5rem;
}

.rightSideB {
  padding-top: 2rem;
  width: 40%;
  display: flex;
}

.rightSideAAA img {
  width: 120%;
}
.rightSideAAB img {
  width: 80%;
  cursor: pointer;
}
.rightSideABA img {
  width: 120%;
}
.rightSideABB img {
  width: 80%;
  cursor: pointer;
}
.rightSideABB img:hover {
  transform: scale(1.1);
}
.menulinks {
  margin-top: 20rem;
}
.menus {
  width: 100% !important;
  background-color: #f0f3f5 !important;
  width: auto !important;
  position: absolute;
  height: auto;
  text-wrap: nowrap;
  border-radius: 0px;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  z-index: 1;
}

.menus li {
  color: #363535 !important;
  display: block !important;
  font-size: 0.8rem;
  line-height: 2rem;
  margin-left: 0 !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  transition: width 0.3s ease;
}
.menus li {
  font-weight: bold;
}
.menus a:hover {
  font-weight: bold;
  color: #363535 !important;
  transition: 0.2s 0.4s !important;
}

.menusNone {
  display: none;
}
.subMenuOpen {
  color: yellow;
}
.subMenuClosed {
  color: #fff;
}

.imageContainer {
  margin-left: 50px;
  padding: 5px 20px;
  width: 100%;
  position: unset !important;
  z-index: 1;
}

.imageCall {
  object-fit: contain;
  width: 80% !important;
  position: relative !important;
  height: unset !important;
}
.mobileMenus ul li {
  line-height: 0.25rem !important;
}
.rightSideB a {
  color: var(--primaryColor);
}

.call {
  color: var(--primaryColor);
  margin-top: 1rem;
  font-size: 0.85rem;
  padding-top: 0.5rem;
}
.call a:hover {
  color: var(--primaryColor);
  font-weight: bold;
}

.socialMedia {
  display: flex;
  text-align: right;
  color: #f0f3f5;
  justify-content: center;
  align-items: center;
}

@media (min-width: 701px) and (max-width: 1120px) {
  .Navbar {
    display: none;
  }
  .imageContainer {
    margin-top: 10px;
    margin-left: 10px;
    padding: 5px 10px;
    width: 100%;
  }
  .image {
    align-items: center;
    width: 100%;
  }

  .menus {
    left: 40%;
    right: unset;
    width: 50%;
  }
}

@media (max-width: 700px) {
  .Navbar {
    display: none;
  }
  .leftSide {
    width: 100%;
    display: flex;
    background-color: brown;
  }
  .rightSide {
    display: none;
  }

  .leftSide button {
    margin: 2rem;
    display: flex;
    width: fit-content;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .imageContainer {
    margin-top: 10px;
    margin-left: 2px;
    padding-top: 0;
    width: 80%;
  }

  .leftSide {
    position: absolute;
    background-color: #f0f3f5;
    left: 0px;
    top: 50px;
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    z-index: 1;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .leftSide li {
    font-size: 0.85rem;
    display: block;
    padding-left: 10px;
  }

  .leftSide a :hover {
    color: #fff !important;
    font-weight: bold;
  }
}
