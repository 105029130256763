/* Styles.css */

.gallery-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.gallery-card {
  width: 25%;
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;
  text-decoration: none;
  color: black;
  border-radius: 0.5rem;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
  rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.gallery-card img {
  max-width: 100%;
  height: auto;
}

.gallery-detail-container {
  text-align: center;
}

.gallery-slides {
  position: relative;
}

.gallery-slides img {
  max-width: 100%;
  height: auto;
  cursor: pointer;
}

.gallery-slides button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  cursor: pointer;
}

.gallery-slides button:hover {
  background-color: #555;
}
