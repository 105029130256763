
.themesContainer {
  padding-left: 10rem;
  width:12%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  gap: 0.5rem;
}

.productCard {
  font-size: small;
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
}

.productCard:hover {
  transform: translateY(-5px);
  cursor: pointer;
}

.productCard img {
  width: 80%;
  object-fit: cover;
}

@media (max-width: 1240px) {
  .themesContainer {
    padding: 1rem;
  }

}

@media (max-width: 700px) {
  .themesContainer {
    width:25%;
    margin-top: 20rem;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    gap: 1px;
    padding: 0.2rem;
  }

  .productCard {
    margin: 0.5rem;
    padding: 0.5rem 0rem;
  }

}
