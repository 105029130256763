.container {
  padding: 0rem 8rem;
  text-align: justify;
  color: var(--secondaryColor);
}
.container h3 {
  color: var(--primaryColor);
}
.container h4 {
  color: var(--primaryColor);
  text-align: left;
}
.container span {
  font-style: italic;
  font-size: small;
}

.container ul {
  background-color: #e0f6ff;
}
.container li {
  list-style-type:lower-greek;
}

.maintable {
  padding: 1rem 1rem;
  width: 100%;
}

.table {
  width: 100%;
  border: 1rem;
  color: var(--secondaryColor);
}

#table p:nth-child(even) {
  background-color: #f2f2f2;
}

#table p:hover {
  background-color: #ddd;
}

#table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}
.highlight {
  color: var(--greyCardTop);
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  margin-bottom: 2rem;
  text-align: justify;
  animation: bounce 1s ease-in-out;
}
.highlight:hover {
  color: var(--primaryColor);
  cursor: pointer;
}
.button {
  width: 15%;
  padding: 0.5rem;
  border: none;
  border-radius: 0.5rem;
  color: #f2f2f2;
  font-weight: bold;
  cursor: pointer;
  background-color: var(--greyCardTop);
}
.button:hover {
  background-color: var(--primaryColor);
}

@media (max-width: 700px) {
  .container {
    padding: 0rem 2rem;
    text-align: justify;
    color: var(--secondaryColor);
  }
}
