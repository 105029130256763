.container {
  width: 90%;
  margin: 2rem 2rem;
  padding: 2rem;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 1rem;
}

.subcontainer {
  display: flex;
  flex-direction: row !important;
  gap: 5rem;
}

.subcontainer input {
  width: 100% !important;
}
.subcontainer select {
  width: 100% !important;
}

.container h2 {
  margin-top: -2rem;
}
.container input {
  width: 95%;
  border: none;
  padding: 0.8rem;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.container c .container input:hover {
  outline: none;
  background-color: #d6ecf6;
}
.container input:focus {
  outline: none;
  background-color: #d6ecf6;
}
.container select {
  border: none;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.container select:hover {
  outline: none;
  background-color: #d6ecf6;
}
.container select:focus {
  outline: none;
  background-color: #d6ecf6;
}
.container label {
  color: var(--primaryColor);
  font-weight: bold;
  margin-top: 0.5rem;
}

.container label {
  display: flex;
  flex-direction: row;
}

.container button {
  float: right !important;
  width: 15%;
  padding: 0.25rem;
  border: none;
  border-radius: 0.5rem;
  color: #f2f2f2;
  font-weight: bold;
  cursor: pointer;
  background-color: var(--greyCardTop);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-left: 1rem;
}
.container button p {
  text-align: center;
}

.container button:hover {
  background-color: var(--lightBlue);
}
.highlight {
  width: 90%;
  color: var(--greyCardTop);
  padding: 3rem;
  border-radius: 0.5rem;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  margin: 2rem;
  animation: bounce 1s ease-in-out;
  justify-content: center;
  align-items: center;
  line-height: 1.5rem;
}
.highlight label:hover {
  cursor: pointer;
  color: aliceblue;
  background-color: var(--lightBlue);
}

.highlight button {
  float: right !important;
  width: 15%;
  padding: 0.25rem;
  border: none;
  border-radius: 0.5rem;
  color: #f2f2f2;
  font-weight: bold;
  cursor: pointer;
  background-color: var(--greyCardTop);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-left: 1rem;
}
.highlight button:hover {
  background-color: var(--lightBlue);
}
.form-group {
  margin-bottom: 1rem;
}
.errors {
  color: red;
}
.allbuttons {
  padding: 2rem;
}

.customRadio {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.customRadio label {
  margin-right: 20px;
  font-size: 16px;
  cursor: pointer;
}

.customRadio input[type="radio"] {
  display: none;
}

.customRadio input[type="radio"] + label::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border: 2px solid #007bff;
  border-radius: 50%;
  vertical-align: middle;
  cursor: pointer;
  transition: background 0.3s;
}

.customRadio input[type="radio"]:checked + label::before {
  background-color: #007bff;
  border-color: #007bff;
}

.customRadio label:hover::before {
  border-color: #0056b3;
}

@media (max-width: 700px) {
  .container {
    display: block;
    padding: 0.5rem;
    width: 95%;
  }
  .subcontainer {
    margin-top: 1.5rem;
    width: 100%;
    display: block;
  }
  .highlight {
    width: 85%;
    padding: 3rem;
  }
  .container button {
    width: 30%;
    padding: 0.5rem;
    gap: 0.15rem;
  }
  .highlight button {
    width: 30%;
    padding: 0.5rem;
    gap: 0.15rem;
  }
  .allbuttons {
    padding: 3rem;
  }
}
