.container {
  padding: 0rem 8rem;
  text-align: justify;
  color: var(--secondaryColor);
}
.container h3 {
  color: var(--primaryColor);
}
.container span {
  color: #4846e2;
}
.maintable {
  padding: 1rem 1rem;
  width: 100%;
}
.table {
  padding-left: 10rem;
  width: 100%;
  border: 1rem;
  color: var(--secondaryColor);
}

#table p:nth-child(even) {
  background-color: #f2f2f2;
}

#table tr:hover {
  background-color: #ddd;
}
#table tr {
}
#table th {
  padding: 0.4rem;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}
.highlight {
  color: var(--greyCardTop);
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  margin-bottom: 1rem;
  text-align: justify;
  animation: bounce 1s ease-in-out;
}
.highlight:hover {
  color: var(--primaryColor);
  cursor: pointer;
}

@media (max-width: 700px) {
  .container {
    padding: 0rem 2rem;
    text-align: justify;
    color: var(--secondaryColor);
  }
  .table {
    padding-left: 2rem;
  }
}
