.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;
}

.container input {
  border: none;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: rgba(131, 130, 130, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.container input:hover {
  outline: none;
  background-color: #d6ecf6;
}
.container input:focus {
  outline: none;
  background-color: #d6ecf6;
}


.container label {
  color: var(--primaryColor);
  font-weight: bold;
  margin-top: 2rem;
}

.container textarea {
  border: none;
  padding: 1rem;
  border-radius: 0.5rem;
  height: 4.5rem;
  box-shadow: rgba(131, 130, 130, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.container textarea:hover {
  outline: none;
  background-color: #d6ecf6;
}
.container textarea:focus {
  outline: none;
  background-color: #d6ecf6;
}

.button {
  margin-top: 1.5rem;
  width: 25%;
  padding: 0.5rem;
  border: none;
  border-radius: 0.5rem;
  color: #f2f2f2;
  font-weight: bold;
  cursor: pointer;
  background-color: var(--lightBlue);
}
.button:hover {
  background-color: var(--primaryColor);
}
