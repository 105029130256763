.container {
  padding: 0rem 0rem;
  text-align: justify;
  color: var(--secondaryColor);
}
.subcontainer {
  padding: 0rem 2rem;
}
.container h1 {
  text-align: center;
}
.container h3 {
  color: var(--primaryColor);
}
.container span {
  color: var(--secondaryColor);
  font-weight: bold;
}
.maintable {
  padding: 1rem 1rem;
  width: 100%;
}
.highlight {
  color: var(--greyCardTop);
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-bottom: 1rem;
  display: flex;
  text-align: justify;
  animation: bounce 1s ease-in-out;
}
.highlight:hover {
  color: var(--primaryColor);
  cursor: pointer;
}
.imageContainer {
  width: 20%;
  padding-left: 1rem;
}
.profileText {
  width: 80%;
  padding: 0rem 2rem;
}
.highlight img {
  border-radius: 0.5rem;
}
.highlight2 {
  color: var(--greyCardTop);
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-bottom: 1rem;
  text-align: justify;
  animation: bounce 1s ease-in-out;
}
.table {
  width: 100%;
  border: 1rem;
  color: var(--secondaryColor);
}

#table p:nth-child(even) {
  background-color: #f2f2f2;
}

#table p:hover {
  background-color: #ddd;
}

#table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}

@keyframes bounce { 
  0%{

  } 
  100% { 
      transform: translateY(0); 
  } 

  50% { 
      transform: translateY(-10px); 
  } 
} 
@media screen and (max-width: 768px) {
  .container {
    padding: 0rem;
    text-align: justify;
    color: var(--secondaryColor);
  }
  .subcontainer {
    padding: 0rem 2rem;
  }
  .highlight {
    display:block
  }
  .imageContainer {
    align-items: center;
    justify-content: center;
    width: 80%;
    padding-left: 1rem;
  }
}